/* eslint-disable max-classes-per-file,class-methods-use-this */
import { action, thunk, computed, actionOn, thunkOn } from 'easy-peasy'

const createModelDecorator = (type, params) => (instance, propertyName) => {
	instance.constructor.addModelMeta(propertyName, {
		type,
		params,
		value: instance[propertyName],
	})
}

export const Action = createModelDecorator('action')
export const ActionOn = params => createModelDecorator('actionOn', params)
export const Computed = params => createModelDecorator('computed', params)
export const Thunk = createModelDecorator('thunk')
export const ThunkOn = params => createModelDecorator('thunkOn', params)

const THUNK_TYPES = Object.freeze({
	START: 'startType',
	FAIL: 'failType',
	SUCCESS: 'successType',
})

class BaseModel {
	get initialData() {
		return {}
	}

	/**
	 * @returns {Readonly<{SUCCESS: string, START: string, FAIL: string}>}
	 */
	static get THUNK_TYPES() {
		return THUNK_TYPES
	}

	static getThunkTypes = (actionFn, types = Object.values(this.THUNK_TYPES)) => {
		return types.map(type => actionFn[type])
	}

	static addModelMeta(key, value) {
		if (!this.modelMeta) {
			this.modelMeta = {}
		}
		this.modelMeta[key] = value
	}

	addTestMeta = (key, value) => {
		this.testMeta[key] = value
	}

	get model() {
		return _.reduce(
			this.constructor.modelMeta,
			(acc, property, propertyName) => {
				switch (property.type) {
					case 'action':
						acc[propertyName] = action(property.value.bind(this))
						break
					case 'actionOn':
						acc[propertyName] = actionOn(property.params.bind(this), property.value.bind(this))
						break
					case 'computed':
						acc[propertyName] = property.params
							? computed(property.params.bind(this), property.value.bind(this))
							: computed(property.value.bind(this))
						break
					case 'thunk':
						acc[propertyName] = thunk(property.value.bind(this))
						break
					case 'thunkOn':
						acc[propertyName] = thunkOn(property.params, property.value.bind(this))
						break
					default:
						break
				}

				return acc
			},
			{
				...this.initialData,
				reset: action(state => {
					_.assignIn(state, this.initialData)
				}),
			},
		)
	}
}

export default BaseModel
